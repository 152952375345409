import ErrorImage from "../../public/images/error/Mango404.png";
import ErrorCard from "./ErrorCard";

function FourOhFourError() {
  return (
    <ErrorCard
      title="Page Not Found"
      subtitle="The page you are looking for might have been removed, had its name changed or is temporarily unavailable."
      image={ErrorImage}
      button="Go home"
      link="/"
    />
  );
}

export default FourOhFourError;
