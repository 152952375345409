import { Provider, useDispatch } from "react-redux";
import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import NavBar from "../components/navigation/NavBar";
import NavSpace from "../components/navigation/NavSpace";
import { store } from "../store/store";
import { setActivePage } from "../features/sidebar/sidebarSlice";
import FourOhFourError from "../common/infoMessages/404Error";

function FourOhFour() {
  const dispatch = useDispatch();
  dispatch(setActivePage(""));

  return (
    <div className="container">
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <section className="section">
        <FourOhFourError />
      </section>
    </div>
  );
}

export default FourOhFour;

FourOhFour.getLayout = function PageLayout(page, session) {
  return (
    <SessionProvider session={session}>
      <Provider store={store}>
        <div>
          <NavBar />
          <div className="wrapper">
            <NavSpace />
            {page}
          </div>
        </div>
      </Provider>
    </SessionProvider>
  );
};
